import type { GalleryData_Item } from 'ricos-schema';
import { convertItemData } from 'wix-rich-content-plugin-gallery/libs/convert-item-data';
import { IFeedItem } from 'api/feed';
import { IMediaItem } from 'store/media/types';
import { getSeoPostData } from 'common/utils/getSeoPostData';

export function convertToProGalleryFormat(items: IFeedItem[]): IMediaItem[] {
  const contentItems = items.flatMap((item) => {
    const {
      galleries = [],
      images = [],
      videos = [],
      gifs = [],
    } = getSeoPostData(item.entity?.body?.content as string);

    const mediaItemsGalleries: GalleryData_Item[] = galleries.flatMap(
      (galleries) => galleries?.items ?? [],
    );

    const mediaItemsImages: Required<Omit<GalleryData_Item, 'video'>>[] =
      images.map((image) => ({
        image: {
          ...image,
          media: image.image,
        },
        title: image.caption ?? '',
        altText: image.altText ?? '',
      }));

    const mediaItemsVideos: Required<Omit<GalleryData_Item, 'image'>>[] =
      videos.map((video) => ({
        video: {
          ...video,
          media: video.video,
          thumbnail: video.thumbnail,
        },
        title: video.title ?? '',
        altText: video.title ?? '',
      }));

    const mediaItemsGifs: Required<Omit<GalleryData_Item, 'image'>>[] =
      gifs.map((gif) => ({
        video: {
          ...gif,
          media: {
            src: {
              url:
                gif?.original?.gif ??
                gif?.original?.mp4 ??
                gif?.original?.still,
            },
            height: gif.height,
            width: gif.width,
          },
          thumbnail: {
            src: {
              url:
                gif?.downsized?.still ??
                gif?.downsized?.gif ??
                gif?.downsized?.mp4,
            },
            height: gif.height,
            width: gif.width,
          },
        },
        title: '',
        altText: '',
      }));

    const mediaItems = [
      ...mediaItemsGalleries,
      ...mediaItemsImages,
      ...mediaItemsVideos,
      ...mediaItemsGifs,
    ];

    return convertItemData(mediaItems);
  });

  return contentItems;
}
